import * as React from 'react'

import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Header from '../components/Header'

// styles
const pageStyles = {
  minHeight: '100vh',
  textAlign: 'left',
  marginTop: '42px',
  marginLeft: '72px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Information about investing and money - moneyoptic</title>
        <meta
          name="description"
          content="Understanding investment risk, volatility and the importance of long term investing."
        />
        <html lang="en" />
      </Helmet>
      <Header />
      <div style={pageStyles}>
        <h2 id="calculators">Calculators</h2>
        <p style={{ marginTop: '8px', marginBottom: '8px' }}>
          <Link to={'/'}>
            Investment calculator - risk and outcome simulator
          </Link>
        </p>
        <p style={{ marginTop: '8px', marginBottom: '8px' }}>
          <Link to={'/calculator/debt/'}>
            Debt payoff - interest and countdown
          </Link>
        </p>
        <br></br>
        <h2 id="calculators">Counters</h2>
        <p style={{ marginTop: '8px', marginBottom: '8px' }}>
          <Link to={'/counter/net-worth/'}>Net worth</Link>
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage
